/* eslint-disable linebreak-style */
/* eslint-disable import/newline-after-import */
import actionsTypes from './actionsTypes';

function onlyDigits(value) {
  if (!value) return '';
  return value.replace(/\D/g, '');
}

const actions = {
  edit: (data = {}) => {
    const payload = data;
    payload.celular = onlyDigits(data.celular);
    payload.document = onlyDigits(data.document);

    return {
      type: actionsTypes.FORM_PERSONAL_DATA_EDIT,
      payload: { ...payload },
    };
  },
  clear: () => ({
    type: actionsTypes.FORM_PERSONAL_DATA_CLEAR,
    payload: {},
  }),
};

export default actions;

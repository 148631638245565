import actionsTypes from './actionsTypes';

const actions = {
  errorsValidationMain: (data) => ({
    type: actionsTypes.ERRORS_VALIDATION_MAIN,
    payload: data,
  }),
};

export default actions;

import React from 'react';
// import config from '../../config';

function Header() {
  return (
    <>
      <div className="container navbar  d-flex p-4 px-md-5 mb-5 border-bottom">
        <a href="/" className="logo mt-1">
          <img src="/images/br5-licencas.png" alt="" />
        </a>
      </div>
    </>
  );
}

export default Header;

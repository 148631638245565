import { combineReducers } from 'redux';

import reducerFormAddress from './formAddress';
import reducerFormCreditCard from './formCreditCard';
import reducerFormPersonalData from './formPersonalData';
import reducerPlan from './plan';
import reducerLoading from './loading';
import reducerShow from './show';
import reducerErrors from './errors';
import reducerCheckout from './checkout';
import reducerTanks from './tanks';
import reducerAddress from './address';
import reducerLead from './lead';

const reducers = combineReducers({
  reducerPlan,
  reducerShow,
  reducerLead,
  reducerTanks,
  reducerErrors,
  reducerLoading,
  reducerAddress,
  reducerCheckout,
  reducerFormAddress,
  reducerFormCreditCard,
  reducerFormPersonalData,
});

export default reducers;

import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionShow from '../../actions/actionShow';

function FormPersonalData() {
  const dispatch = useDispatch();

  const showModalValidationMain = useSelector((state) => state.reducerShow.showModalValidationMain);
  const errorsList = useSelector((state) => state.reducerErrors.errorsValidationMain);

  const handleCancel = () => {
    dispatch(actionShow.showModalValidationMain(false));
  };

  const handleError = (err) => {
    if (!err) return ['Sem erros para mostrar'];
    let result = [];

    if (err && err.list) {
      result = err.list;
    } else if (Array.isArray(err) && err.length) {
      result = err;
    } else if (err instanceof Error) {
      result = [err.message];
    } else {
      result = [err];
    }

    return result;
  };

  return (
    <Modal
      width={window.innerWidth > 960 ? 800 : 'auto'}
      wrapClassName="container"
      title=""
      visible={showModalValidationMain}
      onOk={handleCancel}
      onCancel={handleCancel}
    >
      <div className="text-center">
        <h3 className="title mb-5 mt-3">
          <i className="fas fa-exclamation-triangle mr-2 color-3" />
          Foram encontrados alguns erros:
        </h3>
        <div className="alert alert-info br-5 rounded mt-3">
          <span className="description">
            <i className="fas fa-info-circle mr-2" />
            Por favor, corrija os erros abaixo.
          </span>
        </div>
        {
          errorsList
          && handleError(errorsList).map((error) => (
            <p className="text-3">
              <i className="fas fa-info-circle mr-2 color-1" />
              <b>{error}</b>
            </p>
          ))
        }
      </div>
    </Modal>
  );
}

export default FormPersonalData;

import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

const schemes = {
  personalData: {
    nomeEmpresa: yup
      .string()
      .required('Informe o nome da sua empresa')
      .matches(/\w+\s\w+/, 'O nome deve ser composto por pelo menos duas palavras'),
    nome: yup
      .string()
      .required('Informe o seu nome')
      .matches(/\w+\s\w+/, 'O nome fantasia deve ser composto por pelo menos duas palavras'),
    celular: yup.string().required('informe o seu celular'),
    nascimento: yup.string().required('informe a data de nascimento'),
    document: yup.string().required('informe seu CNPJ ou CPF'),
    email: yup.string().required().email('informe um email válido'),
  },
  formCreditCard: {
    ccNumber: yup.string().required('informe o número do cartão').label('O Número do cartão'),
    ccHolder: yup.string().required('informe o  titular do cartão').label('O titular do cartão'),
    ccMonth: yup.string().required('informe o mês do cartão').min(2).max(2)
      .label('O mês do cartão'),
    ccYear: yup.string().required('informe o ano do cartão').min(2).max(2)
      .label('O ano do cartão'),
    ccCVV: yup.string().required('informe o CVV do cartão').label('O CVV do cartão'),
  },
  installments: {
    installments: yup.number().required('Informe a quantidade de parcelas desejada'),
  },
  formAddress: {
    cep: yup.string().required('informe o cep'),
    rua: yup.string().required('informe a rua'),
    bairro: yup.string().required('informe o bairro'),
    numero: yup.string().required('informe o número'),
    cidade: yup.string().required('informe a cidade'),
    estado: yup.string().required('informe o estado'),
  },
};

function getSchemes(keySchemes) {
  let object = {};

  keySchemes.forEach((key) => {
    object = { ...object, ...schemes[key] };
  });
  const result = yup.object().shape({
    ...object,
  });

  return result;
}

async function validade(keySchemes = [], body) {
  const scheme = getSchemes(keySchemes);
  return new Promise((resolve, reject) => {
    scheme.validate(body, { abortEarly: false })
      .catch((error) => {
        const { errors } = error;
        reject(errors);
      }).then((valid) => {
        resolve(valid);
      });
  });
}

export default {
  validade,
};

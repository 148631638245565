import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import history from './helpers/history';

import Header from './components/layout/Header';
// import Main from './components/main/Main';
import Footer from './components/layout/Footer';
import store from './store';
import Routes from './routes';

function App() {
  return (
    <Provider store={store()}>
      <Router history={history}>
        <div className="pad-4 wrapper-body">
          <Header />
          <Routes />
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;

const actions = {
  ENDERECO_ADD: 'ENDERECO_ADD',
  ENDERECO_REMOVE: 'ENDERECO_REMOVE',
  ENDERECO_IN_EDITION: 'ENDERECO_IN_EDITION',
  ENDERECO_EDIT: 'ENDERECO_EDIT',
  ENDERECO_CLEAR: 'ENDERECO_CLEAR',
  /** ===========================
  * CHECKOUT
  =========================== */
  CHECKOUT_CODE_SMS: 'CHECKOUT_CODE_SMS',
  CHECKOUT_CODE_CONFIRMED: 'CHECKOUT_CODE_CONFIRMED',
  CHECKOUT_VERIFY_CODE: 'CHECKOUT_VERIFY_CODE',
  CHECKOUT_SEND_CODE: 'CHECKOUT_SEND_CODE',
  CHECKOUT_INCREASE_CLIENT: 'CHECKOUT_INCREASE_CLIENT',
  CHECKOUT_DECREASE_CLIENT: 'CHECKOUT_DECREASE_CLIENT',
  CHECKOUT_SET_INITIAL_SETUP: 'CHECKOUT_SET_INITIAL_SETUP',
  CHECKOUT_INSTALLMENTS: 'CHECKOUT_INSTALLMENTS',
  CHECKOUT_ON_CHANGE_QTD: 'CHECKOUT_ON_CHANGE_QTD',

  /** ===========================
   * FORM CREDIT CARD
   =========================== */
  // FORM_CREDIT_CARD_ADD: 'FORM_CREDIT_CARD_ADD',
  FORM_CREDIT_CARD_EDIT: 'FORM_CREDIT_CARD_EDIT',
  FORM_CREDIT_CARD_CLEAR: 'FORM_CREDIT_CARD_CLEAR',
  // FORM_CREDIT_CARD_REMOVE: 'FORM_CREDIT_CARD_REMOVE',

  /** ===========================
   *  ADDRESS FORM
   =========================== */
  // FORM_ADDRESS_ADD: 'FORM_ADDRESS_ADD',
  FORM_ADDRESS_EDIT: 'FORM_ADDRESS_EDIT',
  FORM_ADDRESS_CLEAR: 'FORM_ADDRESS_CLEAR',
  // FORM_ADDRESS_REMOVE: 'FORM_ADDRESS_REMOVE',

  /** ===========================
 * FORM PERSONAL DATA
 =========================== */
  FORM_PERSONAL_DATA_EDIT: 'FORM_PERSONAL_DATA_EDIT',
  FORM_PERSONAL_DATA_CLEAR: 'FORM_PERSONAL_DATA_CLEAR',

  /** ===========================
 * PLAN
 =========================== */
  PLAN_GET_DEFAULT: 'PLAN_GET_DEFAULT',
  PLAN_GET_ALL: 'PLAN_GET_ALL',

  /** ===========================
 * SHOWS
 =========================== */
  SHOW_FORM_ADDRESS: 'SHOW_FORM_ADDRESS',
  SHOW_FORM_CREDIT_CARD: 'SHOW_FORM_CREDIT_CARD',
  SHOW_FORM_PIX: 'SHOW_FORM_PIX',
  SHOW_MODAL_VALIDATION_MAIN: 'SHOW_MODAL_VALIDATION_MAIN',
  SHOW_MODAL_INSTALLMENTS_MAIN: 'SHOW_MODAL_INSTALLMENTS_MAIN',
  SHOW_MODAL_CONFIRM_CODE_SMS: 'SHOW_MODAL_CONFIRM_CODE_SMS',

  /** ===========================
 * LOADING
 =========================== */
  LOADING_PLAN_GET_DEFAULT: 'LOADING_PLAN_GET_DEFAULT',
  LOADING_SUBMIT_CHECKOUT: 'LOADING_SUBMIT_CHECKOUT',
  LOADING_GET_ADDRESS_BY_ZIPCODE: 'LOADING_GET_ADDRESS_BY_ZIPCODE',

  /** ===========================
 * ERRORS
 =========================== */
  ERRORS_VALIDATION_MAIN: 'ERRORS_VALIDATION_MAIN',

  /** ===========================
 * TANKS
 =========================== */
  TANKS_PAYMENT_SET_INFO: 'TANKS_PAYMENT_SET_INFO',
  TANKS_PAYMENT_GET_CHECKOUT: 'TANKS_PAYMENT_GET_CHECKOUT',

  /** ===========================
 * ADDRESS
 =========================== */
  GET_ADDRES_BY_ZIPCODE: 'GET_ADDRES_BY_ZIPCODE',
  /** ===========================
 * LEADS
 =========================== */
  LEAD_GET: 'LEAD_GET',
  LEAD_SAVE: 'LEAD_SAVE',
  LEAD_SAVE_STARTED: 'LEAD_SAVE_STARTED',

};

export default actions;

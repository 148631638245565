/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import cardValidator from 'card-validator';
import actionErrors from '../../actions/actionErrors';
import actionShow from '../../actions/actionShow';
import actionLoading from '../../actions/actionLoading';
import FormCreditCard from './FormCreditCard';
import CreditCardInstallmentsList from './CreditCardInstallmentsList';
import validatorMain from '../../validators/checkout';
import formatBirthDate from '../../util/Formatters/formatBirthDate';
import api from '../../api/instanceMicroservice';

function ModalInstallments() {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  // colocar no cookie
  const params = new URLSearchParams(window.location.search);
  const indication = params.get('indication');

  const { item: plan } = useSelector((state) => state.reducerPlan);
  const showModalInstallmentsMain = useSelector((state) => state.reducerShow.showModalInstallmentsMain);
  const loadingSubmitCheckout = useSelector((state) => state.reducerLoading.submitCheckout);
  const dataCreditCard = useSelector((state) => state.reducerFormCreditCard);
  // const plan = useSelector((state) => state.reducerPlan);
  const dataPersonal = useSelector((state) => state.reducerFormPersonalData);
  const dataAddress = useSelector((state) => state.reducerFormAddress);
  const checkout = useSelector((state) => state.reducerCheckout);

  const handleCancel = () => {
    dispatch(actionShow.showModalInstallmentsMain(false));
  };

  const handleSubmit = async () => {
    try {
      dispatch(actionLoading.loadingSubmitCheckout(true));

      if (!cardValidator.number(dataCreditCard.ccNumber).isValid) {
        throw new Error('Cartão de crédito inválido');
      }
      if (!cardValidator.cvv(dataCreditCard.ccCVV).isValid) {
        throw new Error('CVV do Cartão de crédito inválido');
      }

      const payload = {
        ip: '127.0.0.1', // get ip
        name: dataPersonal.nome,
        cellPhone: dataPersonal.celular,
        birthDate: formatBirthDate(dataPersonal.nascimento),
        document: dataPersonal.document,
        businessName: dataPersonal.nomeEmpresa,
        email: dataPersonal.email,
        plan: plan.identificador,
        indication,
        type: 'billet',
        card: {
          number: dataCreditCard.ccNumber,
          holderName: dataCreditCard.ccHolder,
          expiryMonth: dataCreditCard.ccMonth,
          cvv: dataCreditCard.ccCVV,
          expiryYear: dataCreditCard.ccYear,
          installments: checkout.installments,
        },
        address: {
          addressZipCode: dataAddress.cep,
          addressStreet: dataAddress.rua,
          addressNumber: dataAddress.numero,
          addressDistrict: dataAddress.bairro,
          addressCity: dataAddress.cidade,
          addressState: dataAddress.estado,
          addressComplement: dataAddress.complemento,
        },
      };
      // throw new Error('para ai');
      const result = await api.post('/api/checkout', payload);
      const { data } = result.data;
      window.location.href = `/obrigado/${data.id}`;
    } catch (error) {
      dispatch(actionErrors.errorsValidationMain(error));
      dispatch(actionShow.showModalValidationMain(true));
    } finally {
      dispatch(actionLoading.loadingSubmitCheckout(false));
    }
  };

  const handleNext = async (e) => {
    e.preventDefault();
    try {
      if (step === 0) await validatorMain.validade(['formCreditCard'], dataCreditCard);
      if (step === 1) {
        await validatorMain.validade(['installments'], checkout);
        handleSubmit();
      }

      if (step < 1) setStep(step + 1);
    } catch (error) {
      dispatch(actionErrors.errorsValidationMain(error));
      dispatch(actionShow.showModalValidationMain(true));
    }
  };

  return (
    <Modal
      width={window.innerWidth > 960 ? 800 : 'auto'}
      wrapClassName="container"
      title=""
      visible={showModalInstallmentsMain}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="mb-4">
        <h5 className="mb-5 mt-5">
          <i className="fas fa-credit-card mr-2 color-4" />
          {step === 0 && 'Precisamos dos dados do seu cartão de crédito'}
          {step === 1 && 'Escolha em quantas vezes deseja parcelar'}
          <button
            type="button"
            className="btn btn-sm btn-outline-dark float-right"
            onClick={(e) => {
              e.preventDefault();
              setStep(0);
            }}
          >
            Voltar
          </button>
        </h5>

        {step === 0 && <FormCreditCard />}
        {step === 1 && <CreditCardInstallmentsList />}
      </div>

      <a
        className={`
              link-one title-shadow-one bg-success
              ${loadingSubmitCheckout ? 'link-disabled' : ''}
              `}
        href="asd"
        onClick={(e) => handleNext(e)}
        disabled={loadingSubmitCheckout}
      >
        <h5 className="title-3 text-white text-center">
          <i className="fas fa-lock mr-2" />
          {loadingSubmitCheckout ? 'AGUARDE... ENVIANDO' : 'ASSINAR AGORA!'}
        </h5>
        <span className="line-link-one" />
      </a>
    </Modal>
  );
}

export default ModalInstallments;

import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { formatReal } from '../../helpers/all';

import actionLoading from '../../actions/actionLoading';
import actionCheckout from '../../actions/actionCheckout';

function ProductTop() {
  const params = new URLSearchParams(window.location.search);
  const planCode = params.get('plan');
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.reducerPlan);
  const loading = useSelector((state) => state.reducerLoading.getPlanDefault);
  // const checkout = useSelector((state) => state.reducerCheckout);
  useEffect(async () => {
    if (!Object.keys(plan).length && !plan.error) {
      dispatch(actionLoading.loadingPlanGetDefault(true));
    } else if (Object.keys(plan).length && plan.error) {
      window.location.href = `/erro?str=${plan.message}`;
    } else {
      dispatch(actionLoading.loadingPlanGetDefault(false));
      dispatch(actionCheckout.setInitialSetup({ preco: plan.valor }));
    }
  }, [plan]);

  return (
    <Spin spinning={loading}>
      <div className="wrapper-box">
        <div className="row box-shadow" />
        <div className="row shadow-one-opacited p-5 mb-5">
          <div className="col-md-8 col-sm-12 item-list">
            <div className="icon-item-list float-left mr-5 mt-3 d-none d-sm-block">
              <img src="images/icons/icon-list-2.png" alt="" />
            </div>
            <div className="details-list float-md-left text-center text-md-left mt-4">
              <h4>{plan?.item?.nome}</h4>
              {/* <p className="description">{plan.descricao}</p> */}
              {
                !loading && (
                  <h3 className="title">
                    {planCode === 'vendedorPdv' ? 'Licença Gratuita' : formatReal(plan?.item?.valorAdesao)}
                  </h3>
                )
              }
              {/* <span className="description text-left">abc</span> */}
            </div>
          </div>
          <div className="col-md-4 col-sm-12 text-center">
            <div className="d-block d-md-none">
              {/* <span className="title-3 mr-2">
                Total:
              </span>
              <span className="title loat-left mr-1">{formatReal(checkout.price)}</span> */}
              {/* <span className="description loat-left">{`/ ${plan.ciclo}`}</span> */}
            </div>
          </div>
          {/* <div className="p-1 col-md-4 col-sm-12 text-center">
        </div> */}
        </div>
      </div>
    </Spin>
  );
}

export default ProductTop;

/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/self-closing-comp */
import React from 'react';

function Header() {
  return (
    <>
      <div className="container wrapper-box mt-5">
        <div className="container box-shadow" />
        <div className="shadow-one-opacited p-5">
          <p className="text-center">
            {`BR5 Turbo - Copyrigth ${new Date().getFullYear()} - Todos os direitos reservados `}
          </p>
          <p className="text-center font-weight-bold">
            {`BR5 TURBO PLATAFORMA DIGITAL LTDA`}
          </p>
          <p className="text-center font-weight-bold">
            {`CNPJ: 12.233.290/0001-00`}
          </p>
          {/* <div className="row text-center mb-4 mt-4">
            <b className="col-12 d-block title-3 mb-2">Nossos principais parceiros nacionais</b>
            <div className="col-12">
              <img src="/images/logo-sulamerica.png" alt="" className="img-fluid size-1 mr-3" />
              <img src="/images/logo-drogasil.png" alt="" className="img-fluid size-1 mr-3" />
              <img src="/images/logo-droga-raia.png" alt="" className="img-fluid size-1" />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Header;

import actionsTypes from './actionsTypes';

const actions = {
  loadingPlanGetDefault: (loading) => ({
    type: actionsTypes.LOADING_PLAN_GET_DEFAULT,
    payload: loading,
  }),
  loadingSubmitCheckout: (loading) => ({
    type: actionsTypes.LOADING_SUBMIT_CHECKOUT,
    payload: loading,
  }),
  loadingGetAddressByZipCode: (loading) => ({
    type: actionsTypes.LOADING_GET_ADDRESS_BY_ZIPCODE,
    payload: loading,
  }),
};

export default actions;

import actionsTypes from './actionsTypes';

const actions = {
  edit: (address = {}) => ({
    type: actionsTypes.FORM_ADDRESS_EDIT,
    payload: { ...address },
  }),
  clear: () => ({
    type: actionsTypes.FORM_ADDRESS_CLEAR,
    payload: {},
  }),
};

export default actions;

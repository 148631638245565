import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { message, Spin } from 'antd';
// import moment from 'moment';
import PropTypes from 'prop-types';

import actionTanks from '../../actions/actionTanks';
import { formatReal } from '../../helpers/all';
import config from '../../config';

function Tanks({ match }) {
  const dispatch = useDispatch();
  const tanks = useSelector((state) => state.reducerTanks);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    plan: {},
  });

  useEffect(() => {
    dispatch(actionTanks.getCheckout(match.params.id));
  }, []);

  useEffect(() => {
    if (Object.keys(tanks).length && !tanks.error) {
      setLoading(false);
      setData({
        ...tanks,
        type: tanks.cardRemoteId ? 'creditCard' : 'boleto',
      });
    } else if (Object.keys(tanks).length && tanks.error) {
      window.location.href = `/erro?str=${tanks.message}`;
    } else {
      setLoading(true);
    }
  }, [tanks]);

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    message.success({
      content: 'Código copiado com sucesso!',
    });
  };

  const signature = {
    vendedorPdv: 'VENDEDOR',
    pdv: 'LOJA',
    bigDealer: 'EMPRESÁRIO',

  };

  return (
    <Spin spinning={loading}>
      <div className="container">

        <div className="row shadow-one p-5 mb-5">
          <div className="col-md-12 col-sm-12 text-center">
            <div className="text-center mb-3">
              <img src="/images/thanks.png" className="img-fluid" alt="" />
            </div>
            <span className="text-4">
              {
                data.type === 'boleto'
                  ? (

                    <span>
                      Você pode efetuar o pagamento do seu boleto com pix ou até
                      {' '}
                      <br />
                      mesmo com cartão de crédito no nosso sistema.
                      <br />
                      Você será avisado(a) email assim que o pagamento for concluído.
                    </span>

                  )
                  : 'Agradecemos seu pagamento via cartão de crédito. Seu pagamento foi aprovado E você já pode acessar sua área do sistema'
              }
              <br />

            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-5 col-sm-12 shadow-one p-0 justify-content-center p-0 h-100" style={{ minHeight: '350px' }}>
            <h4 className="title-4 mb-2 pt-5 pl-5 pr-5 text-center">O que você deve fazer agora?</h4>
            <p className="text-2 pl-5 pr-5 d-block mb-5 mt-3">
              Você já pode acessar o sistema e ACELERAR SUA RENDA.
            </p>

            <a
              className="mt-4 link-one link-hover-4 title-shadow-one text-center whatTodoNow"
              href={config.urlApp}
              target="_blank"
              style={{
                bottom: 0,
              }}
              rel="noreferrer"
            >
              <h5 className="title-3 text-white">
                <i className="fas fa-lock mr-2" />
                ACESSAR SISTEMA
              </h5>
              <span className="line-link-one" />
            </a>
          </div>

          <div className="checkout-div col-lg-7 col-md-6 col-sm-12 shadow-one offset-md-1 p-0 h-100" style={{ minHeight: '350px' }}>
            <h4 className="title-4 mb-4 pt-5 pl-5">Informações da assinatura</h4>
            <span className="text-3 text-left pt-2 pl-5 d-block pb-3" style={{ lineHeight: 2 }}>
              <b>Tipo de Assinatura:</b>
              {' '}
              {signature[data.plan]}
              <br />
              <b>Quantidade de funcionários:</b>
              {' '}
              {data.totalLifes}
              <br />
              <b>Valor:</b>
              {' '}
              {formatReal(data.price)}
              <br />
            </span>
            {
              data.type === 'boleto'
                ? (

                  <div className="mt-4">
                    <a
                      className="link-one link-hover-success title-shadow-one bg-success w-50 text-center"
                      href="asd"
                      onClick={(event) => {
                        event.preventDefault();
                        copyToClipboard(data.pixCode);
                      }}
                    >
                      <h5 className="title-3 text-white">
                        <i className="fas fa-lock mr-2" />
                        COPIAR CÓDIGO
                        <br />
                        PIX
                      </h5>
                      <span className="line-link-one" />
                    </a>
                    <a
                      className="link-one link-hover-4 title-shadow-one bg-color-2 w-50 text-center"
                      href="asd"
                      onClick={(event) => {
                        event.preventDefault();
                        copyToClipboard(data.barCode);
                      }}
                    >
                      <h5 className="title-3 text-white">
                        <i className="fas fa-lock mr-2" />
                        COPIAR CÓDIGO
                        <br />
                        DE BARRAS
                      </h5>
                      <span className="line-link-one" />
                    </a>
                  </div>

                ) : <></>

            }

          </div>
        </div>
      </div>
    </Spin>
  );
}

Tanks.propTypes = {
  match: PropTypes.objectOf({}).isRequired,
};

export default Tanks;

import React from 'react';
import PropTypes from 'prop-types';

function NotFound({ match, ...props }) {
  const getMsg = (params = { location: { search: '' } }) => {
    const { search } = params.location;

    let str = search.replace(/\?str=/, '');
    str = str.replace(/%20/gi, ' ');

    return str;
  };

  return (
    <div className="container">

      <div className="row shadow-one p-5 mb-5">
        <div className="col-md-12 col-sm-12 text-center">
          <div className="text-center mb-3">
            <img src="images/erro.png" className="img-fluid" alt="" />
          </div>
          <p className="text-3 mt-2">
            {getMsg(props)}
          </p>
          <a href="/" className="">Página inicial</a>
        </div>
      </div>

    </div>
  );
}

NotFound.propTypes = {
  match: PropTypes.objectOf({}).isRequired,
};

export default NotFound;

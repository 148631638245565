import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionFormAddress from '../../actions/actionFormAddress';
import actionAddress from '../../actions/actionAddress';
import actionLoading from '../../actions/actionLoading';
import actionLead from '../../actions/actionLead';

function FormAddress() {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.reducerFormAddress);
  const zip = useSelector((state) => state.reducerAddress);
  const loadingZipCode = useSelector((state) => state.reducerLoading.getAddressByZipCode);
  const leadStarted = useSelector((state) => state.reducerLead.leadSaveStarted);
  const lead = useSelector((state) => state.reducerLead);

  const getAddressByZipCode = (param = '') => {
    const code = param.replace(/-/, '');
    if (!code || !/^[0-9]{8}$/.test(code)) return;

    dispatch(actionLoading.loadingGetAddressByZipCode(true));
    dispatch(actionAddress.getByZipCode(code));
  };

  const saveLead = () => {
    if (!leadStarted) return;
    const paramsData = { ...lead, ...address };
    delete paramsData.leadSaveStarted;

    dispatch(actionLead.saveLead(paramsData));
  };

  const onChangeFormAddress = (prop, value) => {
    address[prop] = value;
    dispatch(actionFormAddress.edit(address));
    if (prop === 'cep') {
      getAddressByZipCode(value);
    }
    saveLead();
  };

  const fillDataAddress = (params = {}) => {
    address.cep = params.zipCode;
    address.cidade = params.city;
    address.estado = params.state;
    address.bairro = params.district;
    address.rua = params.street;
    dispatch(actionFormAddress.edit(address));
    saveLead();
    dispatch(actionLoading.loadingGetAddressByZipCode(false));
    document.getElementById('addresNumber').focus();
  };

  useEffect(() => {
    if (Object.keys(zip).length) {
      fillDataAddress(zip);
    } else {
      dispatch(actionLoading.loadingGetAddressByZipCode(false));
    }
  }, [zip]);

  return (
    <Spin spinning={loadingZipCode} wrapperClassName="form-billet mt-1 p-3">
      {/* <div className="alert alert-warning br-5 rounded mt-3">
        <span className="description">
          <i className="fas fa-exclamation-triangle mr-2" />
          Preencha os campos abaixo corretamente, usaremos suas informações .
        </span>
      </div> */}
      <form className="text-left">
        <div className="form-group mb-3">
          <p className="description mb-2">CEP:</p>
          <InputMask
            mask="99999-999"
            type="text"
            className="form-control p-4"
            onChange={(event) => onChangeFormAddress('cep', event.target.value)}
            value={address.cep}
          />
        </div>
        <div className="form-group mb-3">
          <p className="description mb-2">RUA:</p>
          <input
            type="text"
            className="form-control p-4"
            onChange={(event) => onChangeFormAddress('rua', event.target.value)}
            value={address.rua}
          />
        </div>
        <div className="row">
          <div className="form-group mb-3 col-md-8 col-sm-12">
            <p className="description mb-2">BAIRRO:</p>
            <input
              type="text"
              className="form-control p-4"
              onChange={(event) => onChangeFormAddress('bairro', event.target.value)}
              value={address.bairro}
            />
          </div>
          <div className="form-group mb-3 col-md-4 col-sm-12">
            <p className="description mb-2">NÚMERO:</p>
            <input
              id="addresNumber"
              type="text"
              className="form-control p-4"
              onChange={(event) => onChangeFormAddress('numero', event.target.value)}
              value={address.numero}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mb-3 col-md-8 col-sm-12">
            <p className="description mb-2">CIDADE:</p>
            <input
              type="text"
              className="form-control p-4"
              onChange={(event) => onChangeFormAddress('cidade', event.target.value)}
              value={address.cidade}
            />
          </div>
          <div className="form-group mb-3 col-md-4 col-sm-12">
            <p className="description mb-2">ESTADO:</p>
            <input
              type="text"
              className="form-control p-4"
              onChange={(event) => onChangeFormAddress('estado', event.target.value)}
              value={address.estado}
            />
          </div>
        </div>
      </form>
    </Spin>
  );
}

export default FormAddress;

/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable linebreak-style */
/* eslint-disable max-len */
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import cardValidator from 'card-validator';

// import api from '../../api';
import api from '../../api/instanceMicroservice';
import {
  // eslint-disable-next-line comma-dangle
  isCNPJ, isCPF, isCellPhone, isValidEmail, isValidCompanyName
} from '../../helpers/all';

import actionErrors from '../../actions/actionErrors';
import actionLoading from '../../actions/actionLoading';
import actionPlan from '../../actions/actionPlan';
import actionShow from '../../actions/actionShow';
import validatorMain from '../../validators/checkout';

import actionLead from '../../actions/actionLead';
import formatBirthDate from '../../util/Formatters/formatBirthDate';
import ActionsForm from './ActionsForm';
import FormAddress from './FormAddress';
import FormPersonalData from './FormPersonalData';
// import ModalConfirmCodeSMS from './ModalConfirmCodeSMS';
import ModalInstallments from './ModalInstallments';
import ModalValidation from './ModalValidation';
import ProductTop from './ProductTop';
import ProductTotal from './ProductTotal';
// import FormCreditCard from './FormCreditCard';

function Main() {
  const params = new URLSearchParams(window.location.search);
  const planCode = params.get('plan') || 'bigDealer';
  const indication = params.get('indication');

  const dispatch = useDispatch();

  const [term, setTerm] = useState(false);
  const { item: plan } = useSelector((state) => state.reducerPlan);
  const showFormBillet = useSelector((state) => state.reducerShow.showFormAddress);
  const showFormCreditCard = useSelector((state) => state.reducerShow.showFormCreditCard);
  // const showModalConfirmCodeSMS = useSelector((state) => state.reducerShow.showModalConfirmCodeSMS);
  const loadingSubmitCheckout = useSelector((state) => state.reducerLoading.submitCheckout);
  // const codeSMS = useSelector((state) => state.reducerCheckout.codeSMS);
  const form = useSelector((state) => state.reducerFormPersonalData);

  const dataAddress = useSelector((state) => state.reducerFormAddress);
  const dataCreditCard = useSelector((state) => state.reducerFormCreditCard);
  const dataPersonal = useSelector((state) => state.reducerFormPersonalData);
  // const checkout = useSelector((state) => state.reducerCheckout);

  useEffect(() => {
    dispatch(actionPlan.getDefault(planCode));
    console.log('plan', planCode);
  }, [planCode]);

  useEffect(() => {
    dispatch(actionShow.showFormCreditCard(false));
    dispatch(actionShow.showFormAddress(true));
  }, []);

   const mountCard = () => ({
     number: dataCreditCard.ccNumber,
     holderName: dataCreditCard.ccHolder,
     expiryMonth: dataCreditCard.ccMonth,
     cvv: dataCreditCard.ccYear,
     expiryYear: dataCreditCard.ccCVV,
   });

  const mountBillet = () => ({
    addressZipCode: dataAddress.cep,
    addressStreet: dataAddress.rua,
    addressNumber: dataAddress.numero,
    addressDistrict: dataAddress.bairro,
    addressCity: dataAddress.cidade,
    addressState: dataAddress.estado,
    addressComplement: dataAddress.complemento,
  });

  const mountPayload = () => {
    const payload = {
      ip: '127.0.0.1', // get ip
      name: dataPersonal.nome,
      cellPhone: dataPersonal.celular,
      birthDate: formatBirthDate(dataPersonal.nascimento),
      document: dataPersonal.document,
      businessName: dataPersonal.nomeEmpresa,
      email: dataPersonal.email,
      plan: planCode,
      indication,
      type: 'billet',
    };

    if (showFormCreditCard) payload.card = mountCard();
    if (showFormBillet) payload.address = mountBillet();

    return payload;
  };

  const handleSubmitVendedor = async () => {
    try {
      dispatch(actionLoading.loadingSubmitCheckout(true));

      const payload = {
        ip: '127.0.0.1', // get ip
        name: dataPersonal.nome,
        cellPhone: dataPersonal.celular,
        birthDate: formatBirthDate(dataPersonal.nascimento),
        document: dataPersonal.document,
        businessName: dataPersonal.nomeEmpresa,
        email: dataPersonal.email,
        plan: plan.identificador,
        indication,
        type: 'billet',
        address: {
          addressZipCode: dataAddress.cep,
          addressStreet: dataAddress.rua,
          addressNumber: dataAddress.numero,
          addressDistrict: dataAddress.bairro,
          addressCity: dataAddress.cidade,
          addressState: dataAddress.estado,
          addressComplement: dataAddress.complemento,
        },
      };
      const result = await api.post('/api/checkout', payload);

      // Verificar se há erro na resposta da API
      if (result.data.error) {
        throw new Error(result.data.error); // Lançar um erro com a mensagem de erro da API
      } else {
        const { data } = result.data;
        window.location.href = `/obrigado/${data.id}`;
      }

      //
    } catch (error) {
      const errorMessage = error.message || 'Email cadastrado';
      dispatch(actionErrors.errorsValidationMain(errorMessage));
      dispatch(actionShow.showModalValidationMain(true));
      dispatch(actionLoading.loadingSubmitCheckout(false));
    }
  };

  const submit = async () => {
    if (planCode === 'vendedorPdv') {
      handleSubmitVendedor();
      return;
    }
    if (showFormCreditCard && planCode !== 'vendedorPdv') {
      dispatch(actionShow.showModalInstallmentsMain(true));
      return;
    }

    if (loadingSubmitCheckout) {
      return;
    }

    dispatch(actionLoading.loadingSubmitCheckout(true));
    try {
      if (!term) {
        throw new Error('Você precisa aceitar os termos do contrato');
      }
      const keys = ['personalData'];
      let body = dataPersonal;

      if (showFormBillet) {
        keys.push('formAddress');
        body = { ...body, ...dataAddress };
      }

      await validatorMain.validade(keys, body);

      if (!isCellPhone(dataPersonal.celular)) {
        throw new Error('Por favor, informe um celular válido.');
      }
      // if (!isValidFullName(dataPersonal.nomeempresa)) {
      //  throw new Error('O nome completo deve ter pelo menos 2 palavras.');
      // }
      if (!isValidEmail(dataPersonal.email)) {
        throw new Error('Por favor, informe um email válido.');
      }
      if (!isValidCompanyName(dataPersonal.nome)) {
        throw new Error('O nome fantasia deve ter pelo menos 2 palavras.');
      }
      if (!isCNPJ(dataPersonal.document) && !isCPF(dataPersonal.document)) {
        throw new Error('Por favor, informe um CPF ou CNPJ válido.');
      }

      const payload = mountPayload();

      // Enviar a solicitação à API
      const result = await api.post('/api/checkout', payload);

      // Verificar se há erro na resposta da API
      if (result.data.error) {
        throw new Error(result.data.error); // Lançar um erro com a mensagem de erro da API
      } else {
        const { data } = result.data;
        window.location.href = `/obrigado/${data.id}`;
      }

      //
    } catch (error) {
      const errorMessage = error.message || 'Email cadastrado';
      dispatch(actionErrors.errorsValidationMain(errorMessage));
      dispatch(actionShow.showModalValidationMain(true));
      dispatch(actionLoading.loadingSubmitCheckout(false));
    }
  };

  /* useEffect(() => {
    if (codeSMS?.confirmed) {
      submit();

    console.log('useefect codesms cojnfirmed', codeSMS);
  }, [codeSMS?.confirmed]); */

  const saveLead = async () => {
    if (!form.celular || !form.nome || !form.email) {
      return;
    }
    if (form.celular.match(/\d/g).length !== 11) {
      return;
    }

    if (!isCellPhone(form.celular)) {
      return;
    }
    try {
      dispatch(actionLead.saveLeadStarted(true)); // Indica que a operação está em andamento

      const keys = ['personalData'];
      let body = dataPersonal;
      if (showFormBillet) {
        keys.push('formAddress');
        body = { ...body, ...dataAddress };
      }

      const validationErrors = await validatorMain.validade(keys, body);

      // Verifique se há erros de validação
      if (Object.keys(validationErrors).length < 0) {
        // Se houver erros, trate-os e não continue com a submissão
        console.error('Erros de validação:', validationErrors);
        /* const errorMessages = Object.values(validationErrors).join(', ');
        const errorMessageElement = document.getElementById('error-message');
        errorMessageElement.textContent = `Erros de validação: ${errorMessages}`;
        errorMessageElement.style.color = 'red'; */
        throw new Error(validationErrors);
      } else {
        // Todas as validações passaram, então agora você pode chamar a função submit
        submit();
      }
    } catch (error) {
      console.error('Erro ao salvar lead:', error);
      const errorMessage = error.message || error;
      dispatch(actionErrors.errorsValidationMain(errorMessage));
      dispatch(actionShow.showModalValidationMain(true));
    } finally {
      dispatch(actionLead.saveLeadStarted(false)); // Indica que a operação terminou
    }
  };

  const handleConfirmCell = async (event) => {
    event.preventDefault();
    saveLead();
  };

  return (
    <Spin spinning={loadingSubmitCheckout}>
      <ModalInstallments />
      {/* { showModalConfirmCodeSMS && <ModalConfirmCodeSMS />} */}
      <ModalValidation />
      <div className="container">
        <ProductTop />
        <div className="row">
          <FormPersonalData />
          <div className="col-md-4 col-sm-12 p-0 offset-md-1">
            <div className="wrapper-box">
              <div className="row box-shadow" />
              <div className=" text-center shadow-one-opacited" style={{ height: 'auto' }}>
                <span className="title-shadow-success title-shadow-one-top" style={{ borderRadius: '5px' }}>
                  <h5 className="title-2 pt-2 text-white">
                    <i className="fas fa-lock mr-2" />
                    COMPRA SEGURA
                  </h5>
                </span>

                <ProductTotal />

                {/* showFormCreditCard && <FormCreditCard /> */}
                <FormAddress />

                <ActionsForm />

                <div className="form-check mb-4 mt-5">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="terms"
                    value={term}
                    onChange={() => setTerm(!term)}
                  />
                  <label className="form-check-label font-weight-bold" htmlFor="terms">
                    Li e aceito os
                    {' '}
                    <a href="/contract-v1.pdf" className="" target="_blank">Termos do Contrato</a>
                  </label>
                </div>
                <a
                  className={`
              link-one link-hover-4 title-shadow-primary bg-success
              ${loadingSubmitCheckout ? 'link-disabled' : ''}
              `}
                  style={{ borderRadius: '5px' }}
                  href="asd"
                  onClick={(event) => handleConfirmCell(event)}
                  disabled={loadingSubmitCheckout}
                >
                  <h5 className="title-3 text-white">
                    <i className="fas fa-lock mr-2" />
                    {loadingSubmitCheckout ? 'AGUARDE... ENVIANDO' : 'ASSINAR AGORA!'}
                  </h5>
                  <span className="line-link-one" />
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default Main;

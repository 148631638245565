import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.ERRORS_VALIDATION_MAIN:
      return { ...state, errorsValidationMain: action.payload };

    default:
      return state;
  }
};

export default reducer;

import actionsTypes from './actionsTypes';
import api from '../api/instanceMicroservice';

const actions = {
  getByZipCode: (code) => ({
    type: actionsTypes.GET_ADDRES_BY_ZIPCODE,
    payload: api.get(`/api/address/zipcode/${code}`),
  }),
};

export default actions;

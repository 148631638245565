import actionsTypes from './actionsTypes';

const actions = {
  showFormAddress: (show) => ({
    type: actionsTypes.SHOW_FORM_ADDRESS,
    payload: show,
  }),
  showFormCreditCard: (show) => ({
    type: actionsTypes.SHOW_FORM_CREDIT_CARD,
    payload: show,
  }),
  showFormPix: (show) => ({
    type: actionsTypes.SHOW_FORM_PIX,
    payload: show,
  }),
  showModalValidationMain: (show) => ({
    type: actionsTypes.SHOW_MODAL_VALIDATION_MAIN,
    payload: show,
  }),
  showModalInstallmentsMain: (show) => ({
    type: actionsTypes.SHOW_MODAL_INSTALLMENTS_MAIN,
    payload: show,
  }),
  showModalConfirmCodeSMS: (show) => ({
    type: actionsTypes.SHOW_MODAL_CONFIRM_CODE_SMS,
    payload: show,
  }),
};

export default actions;

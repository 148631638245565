import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  getPlanDefault: false,
  submitCheckout: false,
  getAddressByZipCode: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.LOADING_PLAN_GET_DEFAULT:
      return { ...state, getPlanDefault: action.payload };

    case actionsTypes.LOADING_SUBMIT_CHECKOUT:
      return { ...state, submitCheckout: action.payload };

    case actionsTypes.LOADING_GET_ADDRESS_BY_ZIPCODE:
      return { ...state, getAddressByZipCode: action.payload };

    default:
      return state;
  }
};

export default reducer;

import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.GET_ADDRES_BY_ZIPCODE:
      return action.payload
      && action.payload.data
      && action.payload.data.data
        ? action.payload.data.data
        : {};

    default:
      return state;
  }
};

export default reducer;

import actionsTypes from './actionsTypes';
import api from '../api';

const actions = {
  all: () => ({
    type: actionsTypes.PLAN_GET_ALL,
    payload: api.get('/api/plan'),
  }),
  getDefault: (plan) => ({
    type: actionsTypes.PLAN_GET_DEFAULT,
    payload: api.get(`services/checkout/plano/${plan}`),
  }),
};

export default actions;

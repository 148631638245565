import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import { formatReal } from '../../helpers/all';

function ProductTotal() {
  const params = new URLSearchParams(window.location.search);
  const planCode = params.get('plan');
  const plan = useSelector((state) => state.reducerPlan);
  const loading = useSelector((state) => state.reducerLoading.getPlanDefault);

  return (
    <Spin spinning={loading}>
      <div style={{ paddingTop: '7rem' }}>
        <h6 className="title-2 mt-3">Valor total:</h6>
        <div className="p-1">
          <span className="title mr-1">{planCode === 'vendedorPdv' ? 'Licença Gratuita' : formatReal(plan?.item?.valorAdesao)}</span>
        </div>
      </div>
    </Spin>
  );
}

export default ProductTotal;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Tanks from '../components/main/Tanks';
import Main from '../components/main/Main';
import NotFound from '../components/404/404';
import Erro from '../components/erro/Erro';

const routes = () => (
  <Switch>
    <Route path="/" exact component={Main} />
    <Route path="/obrigado/:id" exact component={Tanks} />
    <Route path="/404" exact component={NotFound} />
    <Route path="/erro/:str?" exact component={Erro} />
  </Switch>
);

export default routes;

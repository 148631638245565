/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';
import { message } from 'antd';
import config from '../config';

function getArrayStringsValidation(validation) {
  const result = [];

  validation.forEach((err) => {
    result.push(err.message);
  });

  return result;
}

function getResultError(error) {
  const { response = {} } = error;
  const { data } = response;

  const result = {};

  if (data && data.validation) {
    result.validation = data.validation;
    result.list = getArrayStringsValidation(data.validation);
  }

  result.message = error.response
    && error.response.data ? error.response.data.error : 'Erro ao enviar request';

  result.error = error;

  return result;
}

function responseRequest() {
  const instance = axios.create({ baseURL: config.base });
  instance.interceptors.response.use(
    (res) =>
      res,
    (error) => {
      const { response = {} } = error;
      const { data } = response;
      if (data && data.error) {
        // window.location.href = `/erro?str=${data.error}`;
        message.error(data.error, 5);
      }

      const result = getResultError(error);
      return Promise.reject(result);
    },
  );

  return instance;
}

export default responseRequest;

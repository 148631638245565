import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  leadSaveStarted: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.LEAD_SAVE:
      return action.data
        ? { ...state, ...action.data }
        : { ...state };

    case actionsTypes.LEAD_SAVE_STARTED:
      return { ...state, leadSaveStarted: action.payload };

    case actionsTypes.LEAD_GET:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default reducer;

import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.FORM_PERSONAL_DATA_EDIT:
      return action.payload;

    case actionsTypes.FORM_PERSONAL_DATA_CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;

import actionsTypes from './actionsTypes';

const actions = {
  edit: (data = {}) => ({
    type: actionsTypes.FORM_CREDIT_CARD_EDIT,
    payload: { ...data },
  }),
  clear: () => ({
    type: actionsTypes.FORM_CREDIT_CARD_CLEAR,
    payload: {},
  }),
};

export default actions;

import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  // let arrayElement;

  switch (action.type) {
    case actionsTypes.FORM_CREDIT_CARD_EDIT:
      return action.payload;

    case actionsTypes.FORM_CREDIT_CARD_CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;

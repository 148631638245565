import React from 'react';

function NotFound() {
  return (
    <div className="container">

      <div className="row shadow-one p-5 mb-5">
        <div className="col-md-12 col-sm-12 text-center">
          <div className="text-center mb-3">
            <img src="images/404.png" className="img-fluid" alt="" />
          </div>
          <a href="/" className="">Página inicial</a>
        </div>
      </div>

    </div>
  );
}

export default NotFound;

import actionsTypes from './actionsTypes';
import api from '../api/instanceMicroservice';

const actions = {
  increaseClient: (qtd) => ({
    type: actionsTypes.CHECKOUT_INCREASE_CLIENT,
    payload: qtd,
  }),
  decreaseClient: (qtd) => ({
    type: actionsTypes.CHECKOUT_DECREASE_CLIENT,
    payload: qtd,
  }),
  onChangeQtd: (qtd) => ({
    type: actionsTypes.CHECKOUT_ON_CHANGE_QTD,
    payload: qtd,
  }),
  setInitialSetup: (data) => ({
    type: actionsTypes.CHECKOUT_SET_INITIAL_SETUP,
    payload: data,
  }),
  setInstallments: (data) => ({
    type: actionsTypes.CHECKOUT_INSTALLMENTS,
    payload: data,
  }),
  setCodeSMS: (data) => ({
    type: actionsTypes.CHECKOUT_CODE_SMS,
    payload: data,
  }),
  setCodeConfirmed: (data) => ({
    type: actionsTypes.CHECKOUT_CODE_CONFIRMED,
    payload: data,
  }),
  sendCodeConfirmation: (data) => ({
    type: actionsTypes.CHECKOUT_SEND_CODE,
    payload: api.post('/api/checkout/code/send', data),
  }),
  verifyCode: (data) => ({
    type: actionsTypes.CHECKOUT_VERIFY_CODE,
    payload: api.post('/api/checkout/code/verify', data),
  }),
};

export default actions;

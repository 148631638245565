/* eslint-disable spaced-comment */

import React from 'react';
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionShow from '../../actions/actionShow';

function ActionsForm() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.reducerLoading.getPlanDefault);
  const showFormAddress = useSelector((state) => state.reducerShow.showFormAddress);
  // const showFormPix = useSelector((state) => state.reducerShow.showFormPix);
  // const showFormCreditCard = useSelector((state) => state.reducerShow.showFormCreditCard);

  return (
    <Spin spinning={loading}>
      {/*<a
        className="link-one link-hover-4 title-shadow-one bg-color-3 mt-4"
        href="asd"
        onClick={(event) => {
          event.preventDefault();
          dispatch(actionShow.showFormCreditCard(true));
          dispatch(actionShow.showFormAddress(false));
          dispatch(actionShow.showFormPix(false));
          console.log('cartao de credito');
        }}
      >
        <span className={`line-link-one ${showFormCreditCard ? 'line-link-one-fill' : ''}`} />
        <h5 className="title-3 text-white">
          <i className="far fa-credit-card mr-2" />
          CARTÃO DE CRÉDITO
        </h5>
      </a>*/}
      {/* <a
        className="link-one link-hover-4 title-shadow-one bg-color-4 w-50 float-left"
        href="asd"
        onClick={(event) => {
          event.preventDefault();
          dispatch(actionShow.showFormPix(true));
          dispatch(actionShow.showFormCreditCard(false));
          dispatch(actionShow.showFormAddress(false));
        }}
      >
        <span className={`line-link-one ${showFormPix ? 'line-link-one-fill' : ''}`} />
        <h5 className="title-3 text-white">
          <i className="far fa-credit-card mr-2" />
          PIX
        </h5>
      </a>*/}
      <a
        className="link-one link-hover-4 title-shadow-one bg-color-4 "
        href="asd"
        onClick={(event) => {
          event.preventDefault();
          dispatch(actionShow.showFormAddress(true));
          dispatch(actionShow.showFormCreditCard(false));
          dispatch(actionShow.showFormPix(false));
          console.log('boleto');
        }}
      >
        <span className={`line-link-one ${showFormAddress ? 'line-link-one-fill' : ''}`} />
        <h5 className="title-3 text-white">
          <i className="fas fa-file-invoice-dollar mr-2" />
          BOLETO
        </h5>
      </a>
    </Spin>
  );
}

export default ActionsForm;

import React from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import actionCheckout from '../../actions/actionCheckout';
import { formatReal } from '../../helpers/all';

function CreditCardInstallmentsList() {
  const dispatch = useDispatch();
  const loadingSubmitCheckout = useSelector((state) => state.reducerLoading.submitCheckout);
  const checkout = useSelector((state) => state.reducerCheckout);
  const plan = useSelector((state) => state.reducerPlan);

  const handleSubmitInstallments = (value) => {
    dispatch(actionCheckout.setInstallments({ installments: value }));
  };

  return (
    <Spin spinning={loadingSubmitCheckout}>
      <ListGroup>
        {
          plan?.parcelamento?.length && plan?.parcelamento?.map((p, index) => (
            <ListGroupItem
              action
              active={checkout?.installments === index + 1}
              onClick={() => handleSubmitInstallments(index + 1)}
            >
              <div className="d-flex justify-content-between mt-1">

                <div className="d-flex">
                  <span className="h5 font-weight-bold">
                    {p.installments}
                    x
                  </span>

                  <span className="h6 ml-2 mt-1 ">{formatReal(p.value)}</span>
                </div>
                <div className="d-flex ">
                  <span className="h6 mt-1">{formatReal((p.value * p.installments)) }</span>
                  <span className="h5 ml-2">
                    <i className="fas fa-chevron-right" />
                  </span>
                </div>
              </div>
            </ListGroupItem>
          ))
        }
      </ListGroup>
    </Spin>
  );
}

export default CreditCardInstallmentsList;

import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {
  showModalConfirmCodeSMS: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.SHOW_FORM_ADDRESS:
      return { ...state, showFormAddress: action.payload };

    case actionsTypes.SHOW_FORM_CREDIT_CARD:
      return { ...state, showFormCreditCard: action.payload };

    case actionsTypes.SHOW_FORM_PIX:
      return { ...state, showFormPix: action.payload };

    case actionsTypes.SHOW_MODAL_VALIDATION_MAIN:
      return { ...state, showModalValidationMain: action.payload };

    case actionsTypes.SHOW_MODAL_INSTALLMENTS_MAIN:
      return { ...state, showModalInstallmentsMain: action.payload };

    case actionsTypes.SHOW_MODAL_CONFIRM_CODE_SMS:
      return { ...state, showModalConfirmCodeSMS: action.payload };

    default:
      return state;
  }
};

export default reducer;

/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import actionFormPersonalData from '../../actions/actionFormPersonalData';

function FormPersonalData() {
  const dispatch = useDispatch();
  const [typeDoc, setTypeDoc] = useState('cnpj');
  const form = useSelector((state) => state.reducerFormPersonalData);
  const loading = useSelector((state) => state.reducerLoading.getPlanDefault);
  const [errors, setErrors] = useState({
    nomeEmpresa: '',
    nome: '',
    nascimento: '',
    celular: '',
    email: '',
  });

  const onChange = async (prop, value) => {
    form[prop] = value;
    dispatch(actionFormPersonalData.edit(form));

    // Limpa os erros anteriores para o campo
    setErrors({ ...errors, [prop]: '' });
  };

  const renderInputCnpj = () => (
    <div className="form-group mb-3">
      <p className="description mb-2">CNPJ:</p>
      <InputMask
        mask="99.999.999/9999-99"
        type="text"
        id="inp"
        placeholder="&nbsp;"
        value={form.document}
        className="form-control p-4"
        onChange={(event) => onChange('document', event.target.value)}
      />
    </div>
  );

  const renderInputCpf = () => (
    <div className="form-group mb-3">
      <p className="description mb-2">CPF:</p>
      <InputMask
        mask="999.999.999-99"
        type="text"
        id="inp"
        placeholder="&nbsp;"
        value={form.document}
        className="form-control p-4"
        onChange={(event) => onChange('document', event.target.value)}
      />
    </div>
  );

  return (
    <div className="col-md-7 col-sm-12 p-0">
      <div className="wrapper-box">
        <div className="row box-shadow" />
        <Spin spinning={loading} wrapperClassName="shadow-one-opacited">
          <div className="p-5">
            <h4 className="title-2 mb-4">Seus dados</h4>
            <div className="form-group mb-3">
              <p className="title-3 mb-0">Nome fantasia do empreendimento BR5 Turbo:</p>
              <p className="description mb-2 font-weight-light font-italic">Este será o nome da sua licença! Irá aparecer para seus futuros clientes:</p>
              <input
                style={{
                  textTransform: 'uppercase',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  color: '#d9b140',
                }}
                type="text"
                value={form.nome}
                className="form-control p-4"
                onChange={(event) => onChange('nome', event.target.value)}
              />
              {errors.nome && <span style={{ color: 'red' }} className="error-message">{errors.nome}</span>}
            </div>
            <form>
              <div className="form-group mb-3">
                <p className="description mb-0 font-weight-bold">Seu nome completo:</p>
                <p className="description mb-2 font-weight-light font-italic">Seu nome de pessoa física</p>
                <input
                  type="text"
                  value={form.nomeEmpresa}
                  className={`form-control p-4 ${errors.nomeEmpresa ? 'error-input' : ''}`}
                  onChange={(event) => onChange('nomeEmpresa', event.target.value)}
                />
                {errors.nomeEmpresa && <span style={{ color: 'red' }} className="error-message">{errors.nomeEmpresa}</span>}
              </div>
              <div className="form-group mb-3">
                <p className="description mb-0 font-weight-bold">Data de Nascimento:</p>
                <p className="description mb-2 font-weight-light font-italic">Sua data de nascimento</p>
                <InputMask
                  type="text"
                  placeholder="&nbsp;"
                  value={form.nascimento}
                  mask="99/99/9999"
                  className="form-control p-4"
                  onChange={(event) => onChange('nascimento', event.target.value)}
                />
              </div>
              <div className="form-group mb-3">
                <p className="description mb-0 font-weight-bold">Seu celular:</p>
                <p className="description mb-2 font-weight-light font-italic">
                  Seu celular será utilizado para fazer login no sistema.
                  {' '}
                  <br />
                  Então informe um celular válido
                </p>
                <InputMask
                  type="text"
                  id="inp"
                  placeholder="&nbsp;"
                  value={form.celular}
                  mask="(99) 99999-9999"
                  className="form-control p-4"
                  onChange={(event) => onChange('celular', event.target.value)}
                />
              </div>
              <div className="form-group mb-3">
                <p className="description mb-2 d-block">Qual documento deseja informar:</p>
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    className={typeDoc === 'cnpj' ? 'btn bg-default' : 'btn btn-secondary'}
                    onClick={(e) => {
                      e.preventDefault();
                      setTypeDoc('cnpj');
                    }}
                  >
                    CNPJ
                  </button>
                  <button
                    type="button"
                    className={typeDoc === 'cpf' ? 'btn bg-default' : 'btn btn-secondary'}
                    onClick={(e) => {
                      e.preventDefault();
                      setTypeDoc('cpf');
                    }}
                  >
                    CPF
                  </button>
                </div>
              </div>
              {
                typeDoc === 'cpf' ? renderInputCpf() : renderInputCnpj()
              }
              <div className="form-group mb-3">
                <p className="description mb-0 font-weight-bold">EMAIL:</p>
                <p className="description mb-2 font-weight-light font-italic">Seu email ou o email da sua empresa para receber comunicados</p>
                <input
                  type="email"
                  value={form.email}
                  className="form-control p-4"
                  onChange={(event) => onChange('email', event.target.value)}
                />
                {errors.email && <span style={{ color: 'red' }} className="error-message">{errors.email}</span>}
              </div>
            </form>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default FormPersonalData;

import actionsTypes from '../actions/actionsTypes';
import { isNumeric } from '../helpers/all';

const INITIAL_STATE = {
  qtd: 0,
  price: 0,
  individualPrice: 0,
  codeSMS: {
    code: '',
    id: '',
    confirmed: false,
  },
};

const limit = 5000;

const reducer = (state = INITIAL_STATE, action) => {
  let qtd = 0;

  switch (action.type) {
    case actionsTypes.CHECKOUT_INSTALLMENTS:
      return action.payload;

    case actionsTypes.CHECKOUT_SET_INITIAL_SETUP:
      return {
        qtd: 1,
        price: action.payload.preco,
        individualPrice: action.payload.preco,
      };

    case actionsTypes.CHECKOUT_INCREASE_CLIENT:
      qtd = 0;
      qtd = state.qtd + Number(action.payload);
      if (qtd > limit) return state;
      return {
        ...state,
        qtd,
        price: (qtd) * state.individualPrice,
      };

    case actionsTypes.CHECKOUT_DECREASE_CLIENT:
      qtd = 0;
      qtd = state.qtd - Number(action.payload);
      if (qtd <= 0) return state;
      if (qtd > limit) return state;

      return {
        ...state,
        qtd,
        price: qtd * state.individualPrice,
      };

    case actionsTypes.CHECKOUT_ON_CHANGE_QTD:
      qtd = 0;
      if (!isNumeric(action.payload)) return state;

      qtd = Number(action.payload);

      if (qtd <= 1) return state;
      if (qtd > limit) return state;

      return {
        ...state,
        qtd,
        price: qtd * state.individualPrice,
      };

    case actionsTypes.CHECKOUT_CODE_SMS:
      // console.log('CHECKOUT_SEND_CODE', action.payload);
      // console.log('CHECKOUT_SEND_CODE 2', action?.payload?.data || INITIAL_STATE.codeSMS);
      return { ...state, codeSMS: { ...state.codeSMS, code: action?.payload || '' } };

    case actionsTypes.CHECKOUT_SEND_CODE:
      // console.log('CHECKOUT_SEND_CODE', state.codeSMS);
      // console.log('CHECKOUT_SEND_CODE 2', action?.payload?.data?.id);
      return { ...state, codeSMS: { ...state.codeSMS, id: action?.payload?.data?.id || '' } };

    case actionsTypes.CHECKOUT_VERIFY_CODE:
      // console.log('CHECKOUT_CODE_CONFIRMED', action.payload);
      return { ...state, codeSMS: action?.payload?.data || INITIAL_STATE.codeSMS };

    default:
      return state;
  }
};

export default reducer;

import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionsTypes.TANKS_PAYMENT_SET_INFO:
      return action.payload;

    case actionsTypes.TANKS_PAYMENT_GET_CHECKOUT:
      return action.payload
      && action.payload.data
      && action.payload.data.data
        ? action.payload.data.data
        : action.payload;

    default:
      return state;
  }
};

export default reducer;

import actionsTypes from '../actions/actionsTypes';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  // let arrayElement;

  switch (action.type) {
    // case actionsTypes.ENDERECO_ADD:
    //   state.push(action.payload);
    //   return [...state];

    // case actionsTypes.ENDERECO_REMOVE:
    //   arrayElement = state.filter(({ id }) => id.toString() !== action.payload.toString());

    //   return [...arrayElement];

    case actionsTypes.FORM_ADDRESS_EDIT:
      return action.payload;

    case actionsTypes.FORM_ADDRESS_CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;

import axios from 'axios';
import { message } from 'antd';
import config from '../config';

const instance = axios.create({ baseURL: config.baseMicroservice });

function getArrayStringsValidation(validation) {
  const result = [];

  validation.forEach((err) => {
    result.push(err.message);
  });

  return result;
}

function getResultError(error) {
  const { response = {} } = error;
  const { data } = response;

  const result = {};

  if (data && data.validation) {
    result.validation = data.validation;
    result.list = getArrayStringsValidation(data.validation);
  }

  result.message = error.response
    && error.response.data ? error.response.data.error : 'Erro ao enviar request';

  result.error = error;

  return result;
}

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    const { response = {} } = error;
    const { data } = response;
    if (data && data.error) {
      // message.error(data.error, 5);
      console.log('data', data);
      console.log('data.error', data.error);
      console.log('message', message);
      if (data.error === 'Você já é um licenciado ou está em aprovação') {
        data.error = 'Documento já cadastrado';
      }
      if (data.error === 'Email already registered.') {
        data.error = 'Email já cadastrado';
      }
      if (data.error === 'Cell phone already registered.') {
        data.error = 'Número de celular já cadastrado';
      }
    }

    const result = getResultError(error);
    console.log('error', error);
    console.log('result', result);
    return Promise.reject(result);
  },
);

export default instance;

/* eslint-disable radix */
const formatReal = (number = 0) => `R$ ${number.toLocaleString('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})}`;

const isNumeric = (str) => /^-?\d+$/.test(str);

const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

const isValidFullName = (fullName) => {
  const nameRegex = /\w+\s\w+/;
  return nameRegex.test(fullName);
};

const isValidCompanyName = (companyName) => {
  const companyRegex = /\w+\s\w+/;
  return companyRegex.test(companyName);
};

const isCellPhone = (cel) => {
  if (!cel) { return null; }
  let ncel = cel.toString();
  ncel = ncel.replace(/\D/g, '');
  const r = ncel.match(/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[6-9])\d{3})-?(\d{4}))$/);
  if (!r || !r.length || r.length < 1) { return null; }
  return r[0];
};

// const isCellPhone = (cell) => cell.match(/\d/g).length === 10;

const isCNPJ = (c) => {
  if (!c) return false;

  const cnpj = c.toString().replace(/[^\d]+/g, '');

  if (!cnpj || cnpj.length !== 14) return false;
  if (
    cnpj === '00000000000000'
    || cnpj === '11111111111111'
    || cnpj === '22222222222222'
    || cnpj === '33333333333333'
    || cnpj === '44444444444444'
    || cnpj === '55555555555555'
    || cnpj === '66666666666666'
    || cnpj === '77777777777777'
    || cnpj === '88888888888888'
    || cnpj === '99999999999999'
  ) {
    return false;
  }

  let soma = 0;
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let pos = tamanho - 7;

  // eslint-disable-next-line
  for (let i = tamanho; i >= 1; i--) {
    // eslint-disable-next-line
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  // eslint-disable-next-line
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  // eslint-disable-next-line
  if (resultado != digitos.charAt(0)) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  // eslint-disable-next-line
  for (let i = tamanho; i >= 1; i--) {
    // eslint-disable-next-line
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  // eslint-disable-next-line
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  // eslint-disable-next-line
  if (resultado != digitos.charAt(1)) return false; // LINHA 49

  return true; // LINHA 51
};

function isCPF(cpf) {
  if (!cpf) {
    return null;
  }
  // eslint-disable-next-line no-param-reassign
  cpf = cpf.replace(/\D/g, '');
  let soma = 0;
  let resto;

  if (cpf.length !== 11) {
    return false;
  }

  if (
    cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999'
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    soma += parseInt(cpf.substring(i - 1, i), 0) * (11 - i);
  }

  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10), 0)) return false;

  soma = 0;

  for (let i = 1; i <= 10; i += 1) {
    soma += parseInt(cpf.substring(i - 1, i), 0) * (12 - i);
  }

  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11), 0)) return false;

  return true;
}

module.exports = {
  isCNPJ,
  isNumeric,
  formatReal,
  isCellPhone,
  isCPF,
  isValidEmail,
  isValidFullName,
  isValidCompanyName,
};

import React from 'react';
import InputMask from 'react-input-mask';
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import actionFormCreditCard from '../../actions/actionFormCreditCard';

function FormCreditCard() {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.reducerFormCreditCard);
  const loading = useSelector((state) => state.reducerLoading.getPlanDefault);

  const onChange = (prop, value) => {
    form[prop] = value;
    dispatch(actionFormCreditCard.edit(form));
  };

  return (
    <Spin spinning={loading} wrapperClassName="form-credit-card mt-1">
      <div className="alert alert-info br-5 rounded mt-3">
        <span className="description">
          <i className="fas fa-info-circle mr-2" />
          Por favor, preencha os dados solicitados abaixo.
        </span>
      </div>
      <form className="text-left">
        <div className="form-group mb-3">
          <p className="description mb-2">NÚMERO DO CARTÃO:</p>
          <InputMask
            mask="9999 9999 9999 9999"
            type="text"
            className="form-control p-4"
            onChange={(event) => onChange('ccNumber', event.target.value)}
            value={form.ccNumber}
          />
        </div>
        <div className="form-group mb-3">
          <p className="description mb-2">NOME DO TITULAR:</p>
          <input
            type="text"
            className="form-control p-4"
            onChange={(event) => onChange('ccHolder', event.target.value)}
            value={form.ccHolder}
          />
        </div>
        <div className="row">
          <div className="form-group mb-3 col-md-4 col-sm-12">
            <p className="description mb-2">MÊS:</p>
            <InputMask
              mask="99"
              type="text"
              className="form-control p-4"
              onChange={(event) => onChange('ccMonth', event.target.value)}
              value={form.ccMonth}
            />
          </div>
          <div className="form-group mb-3 col-md-4 col-sm-12">
            <p className="description mb-2">ANO:</p>
            <InputMask
              mask="99"
              type="text"
              className="form-control p-4"
              onChange={(event) => onChange('ccYear', event.target.value)}
              value={form.ccYear}
            />
          </div>
          <div className="form-group mb-3 col-md-4 col-sm-12">
            <p className="description mb-2">CVV:</p>
            <input
              type="text"
              className="form-control p-4"
              onChange={(event) => onChange('ccCVV', event.target.value)}
              value={form.ccCVV}
            />
          </div>
        </div>
      </form>
    </Spin>
  );
}

export default FormCreditCard;

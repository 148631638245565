import actionsTypes from './actionsTypes';
import api from '../api/instanceMicroservice';

const actions = {
  getCheckout: (id) => ({
    type: actionsTypes.TANKS_PAYMENT_GET_CHECKOUT,
    payload: api.get(`/api/checkout/${id}`),
  }),
  setInfo: (data) => ({
    type: actionsTypes.TANKS_PAYMENT_SET_INFO,
    payload: data,
  }),
};

export default actions;

/* eslint-disable no-useless-catch */
import actionsTypes from './actionsTypes';
import api from '../api/instanceMicroservice';

const actions = {
  saveLead: async (params) => {
    const payload = {
      ...params,
      name: params.nome || params.name,
      cellPhone: params.celular || params.cellPhone,
      businessName: params.nomeEmpresa || params.businessName,
      birthDate: params.nascimento || params.birthDate,
      document: params.cnpj || params.document,
      addressZipCode: params.cep || params.addressZipCode,
      addressStreet: params.rua || params.addressStreet,
      addressNumber: params.numero || params.addressNumber,
      addressDistrict: params.bairro || params.addressDistrict,
      addressCity: params.cidade || params.addressCity,
      addressState: params.estado || params.addressState,
      addressComplement: params.complemento || params.addressComplement,
    };

    delete payload.nome;
    delete payload.celular;
    delete payload.nomeEmpresa;
    delete payload.nascimento;
    delete payload.cnpj;
    delete payload.cep;
    delete payload.rua;
    delete payload.numero;
    delete payload.bairro;
    delete payload.cidade;
    delete payload.estado;
    delete payload.complemento;
    try {
      const response = await api.post('/api/lead', payload);

      return {
        type: actionsTypes.LEAD_SAVE,
        payload: response.data, // Assuming the response structure has a 'data' field
        data: payload,
      };
    } catch (error) {
      // Handle error
      throw error; // Re-throw the error to be caught by the caller
    }
  },
  getLeads: (data) => ({
    type: actionsTypes.LEAD_GET,
    payload: data,
  }),
  saveLeadStarted: (payload) => ({
    type: actionsTypes.LEAD_SAVE_STARTED,
    payload,
  }),
};

export default actions;
